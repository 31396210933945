
  @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');

body{
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}


*, ::before, ::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
h1, h2, p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  color: black;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #3665ff;
  --gray: #788097;
  --white: #ffffff;
  --background: #fcfcfc;
  --purple: rgb(238 210 255);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --orangeCard: rgba(252, 166, 31, 0.45);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --font : 'Caveat', cursive ;
  --font-Original : 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
@media (min-width: 768px) {
  .container {
    width: 650px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1050px;
  }
}
.container {
  width: 90%;
  margin: auto;
  padding: 40px 0;
}

.App {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: var(--black);
}
.button {
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  position: relative;
  right: -80%;

}
.button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid var(--orange);
  color: var(--orange);
}

@keyframes topUp {
  to {top: 0;}
}
@keyframes Duration {
  to{top : -85px; left :-99px;}
}
@keyframes waite {
  from{margin-left: -150px;}
  to{margin:3px 0}
}

.HOME{
  position: relative;
  height: 100vh;
  padding-right:40px ;
  background: linear-gradient(to right, #434343, #000000); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
  // background: linear-gradient(90.13deg,   #804dee 55%, rgba(60, 51, 80, 0.55) 100%);
}
.backGround {
  background: linear-gradient(to right, #434343, #000000) ; /* Chrome 10-25, Safari 5.1-6 */

}
.Header{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  >div{
    font-family: 'Caveat', cursive !important;
    font-size: 40px;
    a{
      font-family: var(--font);
      text-transform: uppercase;
      font-size: 40px;
      font-weight: bolder;
      -webkit-text-stroke: 1px rgb(255, 255, 255);
      color: white;
      span{
        margin: 0 10px;
      }
    }
  }
  ul{
    display: flex;
    align-items: center;
    @media (max-width:800px) {
      background: linear-gradient(90.13deg,   #2347e9ad 35%, #804dee 130%);
      top: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 40%;
      height: 100vh;
      right: 0;
      transition: .5s;
      z-index: 1000;
      align-items: flex-start;
      justify-content:center;
      gap: 30px;
      li{
        a{
          display: block;
          font-size: 30px;
          text-transform: capitalize;
          &:hover{
            letter-spacing: 2px;
            transition: .5s;
            -webkit-text-stroke: 1px white;
          }
        }

      }
    }
    li{
      margin: 0 10px;
      a{
        letter-spacing: 1px;

        color: white;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        &:hover{
          opacity: .8;
          transition: .5s;
        }
      }
    }
  }

  .toggle{
    display: none;
    position: relative;
    z-index: 400000;
    color: white;
    -webkit-text-stroke: 2px black;
    cursor: pointer;
    @media (max-width:800px) {
      display: block;
    }
    i{


    }
  }
}

.home {
  position: relative;
  height: calc(100vh - 60px);
  width: 100%;
  top: -80px;
  transition: 1s;
  animation: topUp 1s forwards;

  .cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    .shap {
      position: absolute;
      .line{
        transition: 1s;
        position: absolute;
        width: 130px;
        height: 332px;
        top: -130px;
        left: -199px;
        border: 3px solid transparent;
        border-left-color: var(--black);
        border-top-color: var(--black);
        animation: Duration 1s forwards;
        &::before{
          @include mobile{
            left:170px  ;
          }
          content: "";
          position: absolute;
          width: 130px;
          height: 330px;
          left: 267px;
          bottom: -76px;
          border: 3px solid transparent;
          border-right-color: var(--black);
          border-bottom-color: var(--black);
        }
        @include mobile{
          left:-51px !important ;
        }
      }
      .left {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -93px;
        left: 35px;
        border-radius: 50%;
        background-color: var(--black);
                border: 2px dotted white;
        &::before {
          content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 1px;
        left: 23px;
        border-radius: 50%;
        background-color: var(--black);
        border: 2px dotted white;
        }
        &::after {
          content: "";
        position: absolute;
        width: 12px;
    height: 12px;
    top: 3px;
    left: 44px;
        border-radius: 50%;
        background-color: var(--black);
        border: 2px dotted white;
        }
      }
      .right{

        position: absolute;
        width: 20px;
        height: 20px;
        bottom: -57px;
        right: 83px;
        border: 2px dotted white;
        border-radius: 50%;
        background-color: var(--black);
        &::before {
          content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 1px;
        left: -22px;
        border-radius: 50%;
        border: 2px dotted white;
        background-color: var(--black);
        }
        &::after {
          content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 3px;
        left: -39px;
        border-radius: 50%;
        background-color: var(--black);
        border: 2px dotted white;
        }
      }
    }
    img {
    object-fit: contain;
    position: relative;
    min-width: 250px;
    border-radius: 50%;
    top: -13px;
    left: -26px;
    animation: siz 5s forwards;
    @keyframes  siz {
      from{opacity: 0;}
      to{opacity: 1;}
    }
    }
    h2 {
      @media (max-width:600px) {
        white-space: nowrap;
      }
      font-family: var(--font);
      text-transform: uppercase;
    font-size: 30px;
    -webkit-text-stroke: 1px #242d499c;
    color: transparent;
    letter-spacing: 2px;
    width: fit-content;
    cursor: pointer;
    position: relative;
    top: -54px;
    left: -6px;
      &::after {
        content: attr(data-strok);
        position: absolute;
        left: 0;
        color: white;
        overflow: hidden;
        transition: 0.5s;
        animation: Fill 7s infinite linear;
        white-space: nowrap;
      }
      @keyframes Fill {
        0%,
        100% {
          width: 0;
        }
        50% {
          width: 120%;
        }
      }
    }
    ul {
      position: relative;
      display: flex;
      flex-direction: column;
      top: -50px;
      left: -125px;
      z-index: 9999;
      @include mobile{
        left:-75px  ;
      }
      li  {
        margin-top: 10px;
        i {
          &:hover{
            font-size: 50px;
            left: 20px;
            transform: translateX(-10px);
          }
          padding: 10px;
          cursor: pointer;
          font-size: 30px;
          transition: 0.5s;
          background: white;
          // border: 3px solid var(--black);
          line-height: 40px;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 3px 0;
          border-radius: 50%;
          animation: waite 1s forwards ;
          &.fa-facebook {
            color: #1a478a;
            animation-duration: .2s;
          }
          &.fa-envelope {
            color: #ca473a;
            animation-duration: .4s;

          }
          &.fa-whatsapp {
            color: #4dc258;
            animation-duration: .6s;

          }
          &.fa-github {
            color: #000000;
            animation-duration: .8s;

          }
          &.fa-linkedin {
            color: #007bb6;
            animation-duration: 1s;

          }
        }
      }
    }
  }
  .Download{
    color: white;
    // width: 142px;
    @include mobile{
      font-size: 16px ;
    }
    position: relative;
    display: block;
    top: -40px;
    left: -48%;
    padding: 10px;
    text-align: center;
    background-color: var(--black);
    letter-spacing: -1px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: .4s;
    white-space: nowrap;
    &:hover{
      opacity: .8;
    }
  }
}

.skill1 {
  overflow: hidden;
  // background-color: var(--black);
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  min-height: 80vh;
  .container {
    display: grid;
    grid-template-columns: 1fr 250px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    // width: 1000px;
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
  h2{
    text-align: center;
    text-shadow: 10px 15px 20px black;
    .one{
      color: white;
      margin-right: 5px;
      @include mobile{
        font-size:20px  ;
      }
    }
    .two{
      color: #3665ff;
      @include mobile{
        font-size:20px  ;
      }
    }
    .three{
      color: #3665ff;
      font-size: 50px;
      margin-right: 10px;
      @include mobile{
        font-size:35px  ;
      }
    }
    .four{
      color: white;
      font-size: 40px;
      font-weight: normal;
      @include mobile{
        font-size:35px  ;
      }
    }
    .five{
      font-size: 18px;
      color: white;
      @include mobile{
        font-size:18px  ;
      }
    }
    .six{
      font-size: 40px;
      font-family: var(--font);
      color: white;
      -webkit-text-stroke: 1px rgb(255, 255, 255);
      @include mobile{
        font-size:30px  ;
        -webkit-text-stroke: .5px #3665ff;
      }
    }
  }
  h3 {
    padding: 30px 20px 0;
    font-size: 15px;
    font-weight: normal;
    color: white;
    line-height: 1.5;
    letter-spacing: 2px;
    font-weight: normal;
    width: 70%;
    margin: auto;
    -webkit-text-stroke: 1px white;
    text-align: left;
    @include mobile{
      font-size:14px !important ;
    }
    &::first-line {
      font-family: var(--font);
      font-size: 25px;
      margin: 5px 0;
      font-variant: small-caps;
      letter-spacing: 3px;
      color: transparent;
    }
    @media (max-width: 430px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .solar-system {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 20%;
    .orbit {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #007bb6;
      border-radius: 50%;
    }
    .All {
      width: 165px;
      height: 165px;
      animation: spin 12s linear infinite;
      position: relative;
      border-color: #f1ec50;
      box-shadow: 0 0 10px #f1ec50 inset, 0 0 10px #f1ec50;
    }
    .planet {
      position: absolute;
      top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .js {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f1ec50;
    }
    .html {
      width: 110px;
      height: 110px;
      animation: spin 8s linear 0s infinite;
      color: #f18282;
      border-color: #f18282;
      box-shadow: 0 0 10px #f18282f0 inset, 0 0 10px #f18282ef;
    }
    .css {
      width: 60px;
      height: 60px;
      color: #2347e9;
      animation: spin 8s linear 0s infinite;
      position: relative;
      border-color: #2347e9;
      box-shadow: 0 0 10px #2347e9ac inset, 0 0 10px #2347e99d;
    }
    .sun {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      width: 30px;
      height: 30px;
      color: rgba(243, 243, 32, 0.758);
      box-shadow: 0 0 10px rgb(243, 243, 14) inset, 0 0 10px rgb(243, 243, 14);
    }
    .react {
      width: 180px;
      height: 180px;
      display: flex;
      color: #00cdf2;
      &::after {
        position: absolute;
        top: -31px;
        left: -26px;
        content: "";
        border: 1px solid;
        width: 230px;
        height: 230px;
        border-radius: 50%;
        box-shadow: 0 0 10px #00cdf2 inset, 0 0 10px #00cdf2;
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}


.skill2{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  height: 100vh;
  position: relative;
  .container{
    padding: 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    .cover{
      order: 2;
      position: relative;
      width: 500px;
      margin-left: 30px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      transition: .5s ;
      &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        clip-path: polygon(0% 0%, 50% 50%, 0% 100%);
        background-color:rgb(237, 177, 64);
        z-index: 4;
      }
      &:hover img{
        transform: scale(1.3) rotate(9deg);
      }
      &:hover::before{
        opacity: 0;
        transition: .5s;
      }
      @media (max-width:1200px) {
        display: none;
      }
      img{
        width: 100%;
        transition: .5s;
      }
    }
    .box{
      display: flex;
      flex-direction: column;
      margin: auto ;
      border-radius: 10px 10px 0 0;
      width: 600px;
      @media (max-width:600px) {
        width: 400px;
      }
      overflow: hidden;
      h2{
        position: relative;
        left: 40px;
        white-space: nowrap;
        @include mobile{
          font-size: 14px;
        }
        width: 250px;
        padding: 10px 10px 20px;
        font-size: 20px;
        color: transparent;
        font-family: var(--font-Original);
        color: white;
        letter-spacing: -1px;
        display: flex;
        span{
          left: 80px;
          position: absolute;
          margin-left: 20px; 
          white-space: nowrap;
        }
      }
      .progress{
        position: relative;
        @include mobile{
          font-size: 14px;
          width: 80%;
          margin: auto;
        }
        width: 100%;
        height: 5px;
        border-radius: 10px;
        background-color: rgb(223, 223, 223);        
        span{
          
          position: absolute;
          top: 0;
          left: -1px;
          background-color: var(--black);
          height: 100%;
          transition: 2s;
          border-radius:10px 0 0 10px;
          opacity: 0;
          
          &::before{
            content: attr(data-width);
            @include mobile{
              font-size: 14px;
              top: -41px;
              right:-16px ;
            }
            position: absolute;
            top: -47px;
            font-size: 17px;
            background-color: var(--black);
            padding: 4px;
            border-radius: 4px;
            right: -20px;
            color: transparent;
            -webkit-text-stroke: 0.6px white;
          }
          &::after{
            content: "";
            position: absolute;
            border: 10px solid transparent;
            border-top-color: var(--black);
            top: -12px;
            right: -9px;
          }
        }
      }
      .right{
        position: absolute;
        left: 120px;
        color: #eeebebbe;
        animation: Load 4s linear infinite;
      }
      @keyframes Load {
        0% , 100% {left: 120px;}
        50% {left: 180px;}
      }
    }
  }
}


.Portfolio{
  .container{
    position: relative;
    display: grid;
    width: 70%;
    min-height: 100vh;
    grid-template-columns: repeat(auto-fill , minmax(300px , 1fr));

    gap: 30px;
    column-gap: 50px;
    // margin: auto;
    
    .cover{
      border-radius: 10px;
      padding: 20px 20px ;
      width: 350px;
      height: 400px;
      background-color: #151030;
      margin: auto;
      
      .box{
        position: relative;
        border-radius: 10px;
        width: 100%;
        height: 300px;
        object-fit: contain;
        animation:  despear 6s forwards ;
        box-shadow: -12px 14px 89px -36px rgba(36, 53, 235, 0.89);
        overflow: hidden;
        &:hover img{
          transform: scale(1.1) rotate(4deg);
          opacity: .7;
        }
        img{
          width: 100%;
          height: 100%;
          transition: 1s;
          cursor: pointer;
        }
        a{
          position: absolute;
          top: 5px;
          font-size: 40px;
          width: 60px;
          background-color: black;
          color: white;
          font-size: 30px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 10px 10px 10px black inset , 10px 10px 10px rgb(127, 124, 124) inset , 10px 40px 10px rgb(26, 61, 92) inset;
          &:hover{
            opacity: .7;
            transition: .5s;
            box-shadow: 10px 10px 10px rgb(112, 16, 16) inset , 10px 10px 10px rgb(65, 15, 183) inset , 10px 40px 10px rgb(28, 185, 28) inset;

          }
        }
        .githup{
          right: 10px;
        }
        .link{
          left: 10px;
        }
        
      }
      h3{
        text-shadow: 10px 10px 10px var(--black);
        white-space: nowrap;
        font-family: var(--font);
        font-size: 30px;
        color: white;
        -webkit-text-stroke: 2px --black;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}


.contact{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: auto;
  @media (max-width:600px) {
    width: 400px;
    text-align: center;
  }
  h2{
    font-size: 40px;
    color: transparent;
    letter-spacing: -1px;
    -webkit-text-stroke: .9px black;
    font-style: italic;
    text-transform: capitalize;
    span{
      -webkit-text-stroke: .9px orange;
    }
  }
  form{
    margin: 20px;
  .box{
    position: relative;
      margin: 20px 0;
      width: 100%;
      @media (max-width:600px) {
        width: 400px;
      }
      label{
        position: absolute;
        left: 24px;
        top: 7px;
        color: transparent;
        letter-spacing: 1px;
      }
      .Name , .Email , textarea {
        @media (max-width:600px) {
          width: 400px;
        }
        margin: auto;
        width: 500px;
        position: relative;
        display: block;
        height: 40px;
        margin-bottom: 40px;
        border-radius: 10px;
        outline: none;
        border: 1px solid #777;
        padding-left: 20px;
      }
      .Message {
        min-height: 150px;
        max-height: 150px;
        max-width: 500px;
        min-width: 500px;
        &:focus::placeholder{
          opacity: 0;
        }
        @media (max-width:600px) {
          max-width: 400px;
          min-width: 400px;
        }
        
      }
      input:focus{
        border-color: orange;
      }
      input:focus::placeholder{opacity: 0;}
      input:focus + label , textarea:focus + label {
        left: 11px;
        top: -30px;
        font-size: 19px;
        color: orange;
        transition: .5s  ease-out;
      }
    }
    .Submit{
      transition: .5s;
      @media (max-width:600px) {
        right:-36%;
      }
    }
  }
}